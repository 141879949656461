import React from "react"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import Layout from "../layout"
import config from "../../data/SiteConfig"
import ReferenceItems from "../components/ReferenceItems/ReferenceItems"
import Hero from "../components/Hero/Hero"
import GameListing from '../components/GameListing/GameListing'
import GameNav from '../components/GameNav/GameNav'
import NewsListing from '../components/NewsListing/NewsListing'
import BetSiteListing from '../components/BetSiteListing/BetSiteListing'
import BetSiteListing2 from '../components/BetSiteListing2/BetSiteListing2'
import BetBonusListing from '../components/BetBonusListing/BetBonusListing'
import Faq from "../components/Faq/Faq"
import InfoBlocks from "../components/InfoBlocks/InfoBlocks"

class EsportsBetting extends React.Component {
  render () {
    const sites = this.props.data.allPrismicBetsite.edges
    const featuredSites = sites.slice(0, 2)
    // let indexContent = this.props.data.prismicIndex.data
    return (
      <Layout>
        <div className="index-container">
        <Helmet>
                <title>{`${config.siteTitle}`}</title>
                <meta name="description" content={config.siteDescription} />
                <meta name="image" content={config.siteLogo} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:url" content={this.props.location.href} />
                <meta property="og:title" content={config.siteTitle} />
                <meta property="og:description" content={config.siteDescription} />
                <meta property="og:image" content={config.siteLogo} />
                {/* <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ""}/> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""}/>
                <meta name="twitter:title" content={config.siteTitle} />
                <meta name="twitter:description" content={config.siteDescription} />
                <meta name="twitter:image" content={config.siteLogo} />
                <script type="application/ld+json" id="website-json-ld">
                  {JSON.stringify(
                    {
                      "@context":"http://schema.org",
                      "@type":"WebSite",
                      "name":"Esportstips",
                      "alternateName":"esportstips.net",
                      "url":"https://esportstips.net",
                      "@context":"http://schema.org",
                      "@type":"Organization",
                      "name":"Esportstips",
                      "url":"https://esportstips.net",
                      "logo":config.siteLogo,
                      // "sameAs":[
                      //     "https://www.facebook.com/esportstips",
                      //     "https://www.instagram.com/esportstips/",
                      //     "https://twitter.com/esportstips",
                      // ]
                    }
                  )}
                </script>
            </Helmet>
          <section className="section">
            <div className="bg-tint-3"></div>
            <div className="container">
              <div className="columns">
                <div className="column">
                    <h1 className="title">
                      <span className="icon success-icon has-text-success" style={{marginLeft: '0'}}>
                        <i className="material-icons">help_outline</i>
                      </span>
                      Tips on esports betting
                    </h1>
                    <p>
                      <b>Esports betting</b> is a new and fastly growing betting market with new odds and esports games being
                      added on sportsbooks and esports betting sites on a daily basis. Most of the time esports betting is just like any other
                      sports betting but there are some things to consider when placing bets on esports matches and events. Luckily we at Esportstips
                      have created an extensive guide on esports betting to help you place bets on esports.
                    </p>
                </div>
                <div className="column">
                  <BetSiteListing sites={featuredSites} className="bet-site-listing" />
                </div>
              </div>
            </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <h2 className="subtitle">
                <span className="icon success-icon has-text-success" style={{marginLeft: '0'}}>
                  <i className="material-icons">stars</i>
                </span>
                Finding the best esports odds
              </h2>
              <p>
                    There is a large amount of esports betting sites available which each offer varying odds for esports matches
                    and betting markets. It is often wise to search for a betting site which offers the best odds for esports betting
                    since different betting sites can have very different odds ranging from small deviations to more significant differences.
                    While most sites offer very good odds and esports betting markets some sites might have a larger selection of matches and games to choose from.
                    This can have a significant impact on your esports betting strategy and finding the right betting site could be a crucial factor in maximizing your
                    betting returns.
              </p>
              <p>
                    Many betting sites offer opt in bonuses for market selections with irregular odds with a higher than usual risk. Some sites offer risk free opt ins that
                    allow esports gamblers to place nearly risk free bets on some more unsual esports matches or events. Sometimes risk taking is worth it since more riskier bets further
                    incraese your betting returns. It is adviced to carefully read our esports betting site reviews to find various information and ratings about available betting sites.
                    You can read more about our recommended betting sites by visiting our esports betting site reviews pages.
              </p>
              <Link to="/betting-sites">
                <button className="button is-info is-small">Betting site reviews</button>
              </Link>
            </div>
          </section>
          <section className="section">
            <div className="container">
              <h3 className="subtitle">
                <span className="icon success-icon has-text-success" style={{marginLeft: '0'}}>
                  <i className="material-icons">touch_app</i>
                </span>
                Featured esports betting bonuses
              </h3>
              <BetBonusListing sites={sites} className="bet-site-listing" />
            </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <h2 className="subtitle">
                <span className="icon success-icon has-text-success" style={{marginLeft: '0'}}>
                  <i className="material-icons">autorenew</i>
                </span>
                Esports betting bonus wagering requirements
              </h2>
              <p>
                    Betting sites offer great bonuses but sometimes esports gamblers are required to wager their bonus money a certain
                    number of times before they can actually withdraw any winnings. For example you could be given a deposit bonus of 30$ when you register
                    on an esports betting site. In some cases players are required to wager that bonus a certain number of times before they can withdraw any money won by placing
                    opt ins with that bonus. For example with a wagering requirement of 10 times you would have to spend 300$ on betting in order to
                    collect and withdraw winnings generated by your register bonus.
              </p>
              <hr></hr>
              <h3 className="heading">How to find the best wagering requirements?</h3>
              <p className="has-list-items">
                <ul>
                  <li>Compare our recommended esports betting sites and see what kind of promotional offers and bonuses are available.</li>
                  <li>Find information about the betting site wagering requirements and choose a betting site that offers the best terms and conditions for wagering requirements.</li>
                  <li>It is recommended to read the pros and cons about our selected esports betting sites to quickly see what each site has to offer.</li>
                  <li>Register to a selected betting site and earn your promotional welcome bonus.</li>
                  <li>Start placing bets on your preferred esports matches and events to start wagering the bonus money.</li>
                  <li>Once you have wagered your bonuses and opt ins the required amount of times collect your winnigns and withdraw.</li>
                </ul>
              </p>
              {/* <Link to="/betting-sites">
                <button className="button is-info is-small">Betting site reviews</button>
              </Link> */}
            </div>
          </section>
          <section className="section">
            <div className="container">
              <h3 className="subtitle">Get started with esports betting</h3>
              <p>We have selected a featured selection of high ranking esports betting sites which offer an easy
                way to get started with esports betting.
              </p>
              <BetSiteListing2 sites={sites} className="bet-site-listing" />
            </div>
          </section>
          {/* <section className="section content-section">
            <div className="container">
              <InfoBlocks infoItems={indexContent.info_blocks} />
            </div>
          </section>

          <section className="section">
            <div className="container">
              <Faq faqItems={indexContent.faq} />
            </div>
          </section> */}
        </div>
      </Layout>
    );
  }
}

export default EsportsBetting;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query EsportsBettingQuery {
      allPrismicBetsite(limit: 6) {
        edges {
          node {
            uid
            data {
              name {
                text
              }
              website {
                url
              }
              terms_and_conditions_link {
                url
              }
              aff_link {
                url
              }
              bonus
              rating
              brand_color
              logo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    #   prismicEsportsBetting {
    #     data {
    #       faq {
    #         faq_title {
    #           text
    #         }
    #         faq_content {
    #           text
    #         }
    #       }
    #       info_blocks {
    #         info_block_title {
    #           text
    #         }
    #         info_block_content {
    #           html
    #         }
    #         info_block_image {
    #           localFile {
    #             childImageSharp {
    #               fluid {
    #                 ...GatsbyImageSharpFluid
    #               }
    #             }
    #           }
    #         }
    #       }
    #     }
    #   }

  }
`;
